import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import './pie-design-system.scss'
import './helpers.scss'
import './spacing.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { apolloClient } from './graphql/client'
import { ApolloProvider } from '@apollo/client'
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom'
import { RecaptchaVerifier, ConfirmationResult } from '@firebase/auth'
import { Provider } from 'react-redux'
import { Base } from './components/Base/Base'
import {
  feedInvitedPathname,
  invitedPlanPrefix,
  planCreateOrEditPathname,
  signUpOrLogInPathname,
  userProfilePathname,
} from './constants/path-names'
import { initDdLogs } from './plugins/init-dd-logs'
import { initDdRum } from './plugins/init-dd-rum'
import { initMixpanel } from './plugins/init-mixpanel'
import { store } from './store/store'
import { PageType } from './types/page-type.enum'
import { enablePlanCreation } from './constants/constants'
import { HelmetProvider } from 'react-helmet-async'

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier
    confirmationResult: ConfirmationResult
  }
}

store.dispatch({ type: 'window/setWidth', width: window.innerWidth })
window.addEventListener('resize', () => {
  store.dispatch({ type: 'window/setWidth', width: window.innerWidth })
})

initDdRum()
initDdLogs()
initMixpanel()

const routes: RouteObject[] = [
  {
    path: '/messages',
    element: <Base pageType={PageType.messages} />,
  },
  {
    path: '/plans/:id',
    element: <Base pageType={PageType.planDetailPage} />,
  },
  {
    path: `${invitedPlanPrefix}/:id`,
    element: <Base pageType={PageType.planDetailPage} />,
  },
  {
    path: '/',
    element: <Base pageType={PageType.planListPage} />,
  },
  {
    path: signUpOrLogInPathname,
    element: <Base pageType={PageType.signUpOrLogIn} />,
  },
  {
    path: feedInvitedPathname,
    element: <Base pageType={PageType.planListPage} />,
  },
  {
    path: '/feed/:category',
    element: <Base pageType={PageType.planListPage} />,
  },
  {
    path: userProfilePathname,
    element: <Base pageType={PageType.userProfile} />,
  },
  // DEPRECATED: start to use /feed
  {
    path: '/plans',
    element: <Base pageType={PageType.planListPage} />,
  },
  // DEPRECATED: old dynamic link routes would be /?type=channel&id=planning:123
  {
    path: '/',
    element: <Base pageType={PageType.planDetailPage} />,
  },
]

if (enablePlanCreation) {
  routes.push({
    path: planCreateOrEditPathname,
    element: <Base pageType={PageType.planCreatePage} />,
  })
}

const router = createBrowserRouter(routes)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const helmetContext = {}
root.render(
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <HelmetProvider context={helmetContext}>
        <RouterProvider router={router} />
      </HelmetProvider>
    </Provider>
  </ApolloProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
